




















































/* eslint-disable no-useless-escape */

// @ is an alias to /src
import { Component, Vue } from 'vue-property-decorator';
import Player from '@/models/player';
import playerList from '@/components/PlayerList.vue';
import importetRules from '@/rules';
import Socket from '../services/socket';
import Ruleset from '../models/ruleset';
import TruthOrDare from '@/rules/truthOrDare.json';
import Roompot from '@/rules/roompot.json';
import Windeck from '@/rules/Windeck1.0.json';

@Component({
  components: { playerList },
})
export default class NewGame extends Vue {
  private socket = new Socket();

  private mounted() {
    if (!this.gameModeMultiplayer) return;
    Socket.mySocket.on('gameStarted', () => {
      this.$router.push({ path: 'game' });
    });
    Socket.mySocket.on('reconnect', () => {
      Socket.mySocket.emit('reconnectSocket', {
        lobby: Socket.lobby,
        ownLobby: Socket.mySocket.id,
      });
    });
    Socket.mySocket.on('rulesetUpdated', (ruleset: Ruleset) => {
      Socket.ruleset = ruleset;
      this.$store.commit('setRuleset', ruleset);
    });
    Socket.mySocket.on('playersUpdated', (newPlayers: Player[]) => {
      this.$store.commit('setPlayers', newPlayers);
    });
  }

  private get gameModeMultiplayer() {
    return this.$store.state.gameModeMultiplayer;
  }

  private get yourId() {
    return this.$store.state.yourId;
  }

  private get enableSecretModeCode() {
    return this.$store.state.enableSecretModeCode;
  }

  //Add new Rulesets in importedRules
  private get rulesets() {
    // TODO Paywall
    const ruleSets = [];
    for (const variable in importetRules) {
      ruleSets.push({
        value: importetRules[variable],
        text: importetRules[variable][this.$root.$i18n.locale].name,
      });
    }
    if (this.enableSecretModeCode === 'TruthOrDare') {
      ruleSets.push({
        value: TruthOrDare,
        text: TruthOrDare[this.$root.$i18n.locale].name,
      });
    }
    if (this.enableSecretModeCode === 'NoKautie') {
      ruleSets.push({
        value: Roompot,
        text: Roompot[this.$root.$i18n.locale].name,
      });
    }
    if (this.enableSecretModeCode === 'Windeck') {
      ruleSets.push({
        value: Windeck,
        text: Windeck[this.$root.$i18n.locale].name,
      });
    }
    return ruleSets;
  }

  private get ruleset() {
    return this.$store.state.ruleset;
  }

  private set ruleset(ruleset: Ruleset) {
    this.$store.dispatch('setRuleset', ruleset);
  }

  private get players(): Player[] {
    return this.$store.state.players;
  }
  private addPlayer(newPlayer: Player) {
    this.$store.dispatch('addPlayer', newPlayer);
  }
  private deletePlayer(playerId: number) {
    this.$store.dispatch('deletePlayer', playerId);
  }
  private startNewGame() {
    if (this.players.length > 1 && Object.keys(this.ruleset).length > 0) {
      if (this.gameModeMultiplayer) {
        Socket.mySocket.emit('startGame', Socket.lobby);
      } else {
        this.$router.push({ path: 'game' });
      }
    }
  }
}
