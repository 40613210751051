











































































// @ is an alias to /src
import { Component, Vue } from 'vue-property-decorator';
import Player from '../models/player';
import Socket from '../services/socket';
import CONSTANTS from '@/constants';
import Ruleset from '../models/ruleset';
import { EventEmitterBus } from '../EventBus';

@Component({
  components: {},
})
export default class NewGame extends Vue {
  private colors = CONSTANTS.COLORS;
  private playerName = '';
  private playerColor = '';
  private socket = new Socket();
  private registration = null;
  private get title() {
    return this.$t('title');
  }
  private get okTitle() {
    return this.$t('okTitle');
  }
  private get cancleTitle() {
    return this.$t('cancleTitle');
  }

  private async mounted() {
    EventEmitterBus.on('newContentAvailable', () => {
      (this.$refs['newContent'] as any).show();
    });
    console.log(process.env.VUE_APP_TEST);

    if (Socket.mySocket === null) {
      this.socket.setMySocket();
    }

    if (this.$route.query.lobby) {
      await this.socket.joinLobby((this.$route.query.lobby as string) + 'All');
      await this.playersUpdated();
      await this.rulesetUpdated();
      (this.$refs['lobby'] as any).show();
    }
    (this.$refs['disclaimer'] as any).show();
  }

  private playersUpdated() {
    Socket.mySocket.on('playersUpdated', (newPlayers: Player[]) => {
      this.$store.commit('setPlayers', newPlayers);
    });
  }
  public rulesetUpdated() {
    Socket.mySocket.on('rulesetUpdated', (ruleset: Ruleset) => {
      Socket.ruleset = ruleset;
      this.$store.commit('setRuleset', ruleset);
    });
  }

  private get players() {
    return this.$store.state.players;
  }

  private get playerColors(): string[] {
    // Todo any
    const colors: any[] = [...this.colors];

    this.players.forEach((element) => {
      if (
        colors
          .map((e) => {
            return e.value;
          })
          .indexOf(element.color) > -1
      ) {
        colors.splice(
          colors
            .map((e) => {
              return e.value;
            })
            .indexOf(element.color),
          1
        );
      }
    });
    return colors;
  }

  private singleplayer() {
    this.$store.commit('gameModeMultiplayer', false);
    this.socket.deleteSocket();
    this.goToNewGame();
  }
  private multiplayer() {
    (this.$refs['lobby'] as any).show();
  }

  private handleOk(bvModalEvt) {
    bvModalEvt.preventDefault();

    (this.$refs.valid as any).validate().then(async (success: boolean) => {
      if (success) {
        const activeTurn = this.players.length === 0 ? true : false;
        const newPlayer: Player = {
          id: this.players.length,
          name: this.playerName,
          tile: 0,
          activeTurn: activeTurn,
          color: this.playerColor,
        };

        const lobby = !this.$route.query.lobby
          ? Socket.mySocket.id + 'All'
          : ((this.$route.query.lobby + 'All') as string);

        if (!this.$route.query.lobby) {
          await this.socket.joinLobby(Socket.mySocket.id + 'All');
          await this.playersUpdated();
          await this.rulesetUpdated();
          Socket.mySocket.emit('getPlayerFromSocket', lobby);
        }
        (this.$refs['lobby'] as any).show();

        Socket.mySocket.emit('addPlayerToSocket', {
          newPlayer,
          lobby,
          ownLobby: Socket.mySocket.id,
        });

        Socket.mySocket.on('playersNotUpdated', (color: string) => {
          window.alert(color + ' wird schon verwendet');
        });
        Socket.mySocket.on('goToNewGame', () => {
          this.$store.commit('gameModeMultiplayer', true);
          this.$store.commit('setYourId', this.players.length - 1);

          this.goToNewGame();
        });
      }
    });
  }

  private goToNewGame() {
    this.$router.push({ path: 'newGame' });
  }
}
