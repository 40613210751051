







































import { Component, Vue } from 'vue-property-decorator';

@Component({
  components: {},
})
export default class Dice extends Vue {
  // BUllshit remove later
  private get ruleset() {
    return this.$store.state.ruleset;
  }
  private get players() {
    return this.$store.state.players;
  }
  //POINT ROLL FUNCTION
  private roll() {
    const elDiceOne = document.getElementById('dice1')!;
    // sets dice variables
    const diceOne = Math.floor(Math.random() * 6) + 1;
    // const diceOne = 37;
    //Dice reset and display

    for (let i = 1; i <= 6; i++) {
      elDiceOne.classList.remove('show-' + i);
      if (diceOne === i) {
        elDiceOne.classList.add('show-' + i);
      }
    }
    return diceOne;
  }
}
