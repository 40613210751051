var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"tileContainer",class:[
    { borderBottom: _vm.borderBottom },
    { borderLeft: _vm.borderLeft },
    { borderRight: _vm.borderRight },
    { tileMoveForward: _vm.tileMoveForward },
    { tileMoveBackward: _vm.tileMoveBackward },
    { tileRule: _vm.tileRule },
    { tileHighlighted: _vm.tileHighlighted } ],style:(_vm.highlightStyle)},[_c('div',{staticClass:"tile",attrs:{"id":_vm.fieldId}},[_c('span',{staticClass:"responsiveNumber ml-2"},[_vm._v(_vm._s(_vm.fieldNumber))]),_c('br'),_c('div',{staticClass:"responsiveText ml-2"},[_c('strong',[_vm._v(_vm._s(_vm.rule))])])])])}
var staticRenderFns = []

export { render, staticRenderFns }