export default class Ruleset {
  name = "";
  fieldId0: Rule = { name: "", description: "", move: 0, rulerule: "" };
  fieldId1: Rule = { name: "", description: "", move: 0, rulerule: "" };
  fieldId2: Rule = { name: "", description: "", move: 0, rulerule: "" };
  fieldId3: Rule = { name: "", description: "", move: 0, rulerule: "" };
  fieldId4: Rule = { name: "", description: "", move: 0, rulerule: "" };
  fieldId5: Rule = { name: "", description: "", move: 0, rulerule: "" };
  fieldId6: Rule = { name: "", description: "", move: 0, rulerule: "" };
  fieldId7: Rule = { name: "", description: "", move: 0, rulerule: "" };
  fieldId8: Rule = { name: "", description: "", move: 0, rulerule: "" };
  fieldId9: Rule = { name: "", description: "", move: 0, rulerule: "" };
  fieldId10: Rule = { name: "", description: "", move: 0, rulerule: "" };
  fieldId11: Rule = { name: "", description: "", move: 0, rulerule: "" };
  fieldId12: Rule = { name: "", description: "", move: 0, rulerule: "" };
  fieldId13: Rule = { name: "", description: "", move: 0, rulerule: "" };
  fieldId14: Rule = { name: "", description: "", move: 0, rulerule: "" };
  fieldId15: Rule = { name: "", description: "", move: 0, rulerule: "" };
  fieldId16: Rule = { name: "", description: "", move: 0, rulerule: "" };
  fieldId17: Rule = { name: "", description: "", move: 0, rulerule: "" };
  fieldId18: Rule = { name: "", description: "", move: 0, rulerule: "" };
  fieldId19: Rule = { name: "", description: "", move: 0, rulerule: "" };
  fieldId20: Rule = { name: "", description: "", move: 0, rulerule: "" };
  fieldId21: Rule = { name: "", description: "", move: 0, rulerule: "" };
  fieldId22: Rule = { name: "", description: "", move: 0, rulerule: "" };
  fieldId23: Rule = { name: "", description: "", move: 0, rulerule: "" };
  fieldId24: Rule = { name: "", description: "", move: 0, rulerule: "" };
  fieldId25: Rule = { name: "", description: "", move: 0, rulerule: "" };
  fieldId26: Rule = { name: "", description: "", move: 0, rulerule: "" };
  fieldId27: Rule = { name: "", description: "", move: 0, rulerule: "" };
  fieldId28: Rule = { name: "", description: "", move: 0, rulerule: "" };
  fieldId29: Rule = { name: "", description: "", move: 0, rulerule: "" };
  fieldId30: Rule = { name: "", description: "", move: 0, rulerule: "" };
  fieldId31: Rule = { name: "", description: "", move: 0, rulerule: "" };
  fieldId32: Rule = { name: "", description: "", move: 0, rulerule: "" };
  fieldId33: Rule = { name: "", description: "", move: 0, rulerule: "" };
  fieldId34: Rule = { name: "", description: "", move: 0, rulerule: "" };
  fieldId35: Rule = { name: "", description: "", move: 0, rulerule: "" };
  fieldId36: Rule = { name: "", description: "", move: 0, rulerule: "" };
  fieldId37: Rule = { name: "", description: "", move: 0, rulerule: "" };
  fieldId38: Rule = { name: "", description: "", move: 0, rulerule: "" };
  fieldId39: Rule = { name: "", description: "", move: 0, rulerule: "" };
  fieldId40: Rule = { name: "", description: "", move: 0, rulerule: "" };
  fieldId41: Rule = { name: "", description: "", move: 0, rulerule: "" };
  fieldId42: Rule = { name: "", description: "", move: 0, rulerule: "" };
  fieldId43: Rule = { name: "", description: "", move: 0, rulerule: "" };
  fieldId44: Rule = { name: "", description: "", move: 0, rulerule: "" };
  fieldId45: Rule = { name: "", description: "", move: 0, rulerule: "" };
  fieldId46: Rule = { name: "", description: "", move: 0, rulerule: "" };
  fieldId47: Rule = { name: "", description: "", move: 0, rulerule: "" };
  fieldId48: Rule = { name: "", description: "", move: 0, rulerule: "" };
  fieldId49: Rule = { name: "", description: "", move: 0, rulerule: "" };
  fieldId50: Rule = { name: "", description: "", move: 0, rulerule: "" };
  fieldId51: Rule = { name: "", description: "", move: 0, rulerule: "" };
  fieldId52: Rule = { name: "", description: "", move: 0, rulerule: "" };
  fieldId53: Rule = { name: "", description: "", move: 0, rulerule: "" };
  fieldId54: Rule = { name: "", description: "", move: 0, rulerule: "" };
  fieldId55: Rule = { name: "", description: "", move: 0, rulerule: "" };
  fieldId56: Rule = { name: "", description: "", move: 0, rulerule: "" };
  fieldId57: Rule = { name: "", description: "", move: 0, rulerule: "" };
  fieldId58: Rule = { name: "", description: "", move: 0, rulerule: "" };
  fieldId59: Rule = { name: "", description: "", move: 0, rulerule: "" };
  fieldId60: Rule = { name: "", description: "", move: 0, rulerule: "" };
  fieldId61: Rule = { name: "", description: "", move: 0, rulerule: "" };
  fieldId62: Rule = { name: "", description: "", move: 0, rulerule: "" };
  fieldId63: Rule = { name: "", description: "", move: 0, rulerule: "" };
  fieldId64: Rule = { name: "", description: "", move: 0, rulerule: "" };
  fieldId65: Rule = { name: "", description: "", move: 0, rulerule: "" };
  fieldId66: Rule = { name: "", description: "", move: 0, rulerule: "" };
  fieldId67: Rule = { name: "", description: "", move: 0, rulerule: "" };
  fieldId68: Rule = { name: "", description: "", move: 0, rulerule: "" };
  fieldId69: Rule = { name: "", description: "", move: 0, rulerule: "" };
  fieldId70: Rule = { name: "", description: "", move: 0, rulerule: "" };
  fieldId71: Rule = { name: "", description: "", move: 0, rulerule: "" };
}

interface Rule {
  name: string;
  description: string;
  move: number;
  rulerule: string;
}
