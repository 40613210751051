



















































// @ is an alias to /src
import { Component, Vue } from 'vue-property-decorator';
import tile from '@/components/Tile.vue';
import player from '@/components/Player.vue';
import dice from '@/components/Dice.vue';
import Player from '@/models/player';
import Socket from '../services/socket';
import constants from '../constants';

@Component({
  components: { tile, player, dice },
})
export default class Game extends Vue {
  private rulename = '';
  private ruledescription = '';
  private rulerule = { en: '', de: '' };
  private ruleMovement = 0;
  private diceable = true;
  private popUpOpen = true;
  private random = 0;

  private get reactiveRulerule() {
    return this.rulerule[this.$root.$i18n.locale];
  }

  private socket = new Socket();

  private matrix: number[][] = [
    [0, 1, 2, 3, 4, 5, 6, 7, 8],
    [29, 30, 31, 32, 33, 34, 35, 36, 9],
    [28, 51, 52, 53, 54, 55, 56, 37, 10],
    [27, 50, 65, 66, 67, 68, 57, 38, 11],
    [26, 49, 64, 71, 70, 69, 58, 39, 12],
    [25, 48, 63, 62, 61, 60, 59, 40, 13],
    [24, 47, 46, 45, 44, 43, 42, 41, 14],
    [23, 22, 21, 20, 19, 18, 17, 16, 15],
  ];

  private async mounted() {
    this.players.forEach((element) => {
      (this.$refs.player as any)[element.id].movePlayerAutonom(element.tile);
    });

    if (!this.gameModeMultiplayer) return;
    Socket.mySocket.on('reconnect', () => {
      Socket.mySocket.emit('reconnectSocket', {
        lobby: Socket.lobby,
        ownLobby: Socket.mySocket.id,
      });
    });
    Socket.mySocket.on('playersUpdated', (newPlayers: Player[]) => {
      this.$store.commit('setPlayers', newPlayers);
    });
    Socket.mySocket.on('diceWasRolled', async (payload) => {
      this.players = payload.players;
      let id = 0;

      this.players.forEach((element: Player) => {
        if (element.activeTurn) {
          id = element.id;
        }
      });
      this.roll = payload.roll;
      if (Math.abs(this.roll) >= 10) {
        await new Promise((resolve) => {
          setTimeout(() => {
            resolve(
              Socket.mySocket.emit('showRuleInSocket', {
                id,
                lobby: Socket.lobby,
              })
            );
          }, 500);
        });
      } else {
        await new Promise((resolve) => {
          setTimeout(() => {
            resolve(
              Socket.mySocket.emit('showRuleInSocket', {
                id,
                lobby: Socket.lobby,
              })
            );
          }, 500 * (Math.abs(this.roll) + 2));
        });
      }
    });
    Socket.mySocket.on('okHasBeenClicked', () => {
      this.okClicked();
    });
    Socket.mySocket.on('ruleOpened', (payload) => {
      this.players = payload.players;
      if (this.popUpOpen) {
        this.showRule(payload.id);
      }
    });
    Socket.mySocket.on('nextTurn', (players) => {
      let id = 0;

      players.forEach((element: Player) => {
        if (element.activeTurn) {
          id = element.id;
        }
      });

      this.players = players;
      this.activePlayer = players[id];
      if (this.yourId === this.activePlayer.id) {
        if (this.settings.vibration) {
          window.navigator.vibrate(1000);
        }
      }
      this.diceable = true;
    });
    Socket.mySocket.on('popUpUpdated', (payload) => {
      this.popUpOpen = payload.popUpOpen;
      this.random = payload.random;
      if (!this.popUpOpen) {
        this.$nextTick(() => {
          (this.$refs['rule'] as any).hide();
        });
      }
    });
    Socket.mySocket.on('gotUpdate', (newPlayers) => {
      this.$store.commit('setPlayers', newPlayers);
    });
    window.addEventListener('focus', () => {
      Socket.mySocket.emit('getUpdate', {
        lobby: Socket.lobby,
        ownLobby: Socket.mySocket.id,
      });
      setTimeout(() => {
        Socket.mySocket.emit('updatePopUpOpen', Socket.lobby);
      }, 1000);
    });
  }

  private get settings() {
    return this.$store.state.settings;
  }

  private get gameModeMultiplayer() {
    return this.$store.state.gameModeMultiplayer;
  }
  private get yourId() {
    return this.$store.state.yourId;
  }
  private get ruleset() {
    return this.$store.state.ruleset[this.$root.$i18n.locale];
  }

  private get bothRulesets() {
    return this.$store.state.ruleset;
  }

  private get isRight() {
    return this.right;
  }

  private get isActive() {
    let active = false;
    if (this.gameModeMultiplayer) {
      active = this.yourId === this.activePlayer.id ? true : false;
    }
    return active;
  }

  private right = false;

  private get players(): Player[] {
    return this.$store.state.players;
  }
  private set players(players: Player[]) {
    this.$store.commit('setPlayers', players);
  }

  private roll = 0;
  private activePlayer = this.players[0];

  private async rollTheDie() {
    if (!this.gameModeMultiplayer || this.yourId === this.activePlayer.id) {
      if (this.diceable) {
        this.diceable = false;
        let id = 0;

        this.players.forEach((element: Player) => {
          if (element.activeTurn) {
            id = element.id;
          }
        });
        this.roll = (this.$refs.dice as any).roll();

        if (this.gameModeMultiplayer) {
          await Socket.mySocket.emit('moveInSocket', {
            roll: this.roll,
            playerId: id,
            lobby: Socket.lobby,
          });
        } else {
          await this.move(id);
          this.diceable = true;
        }
      }
    }
  }

  private async handleOk(bvModalEvt) {
    bvModalEvt.preventDefault();

    if (this.gameModeMultiplayer) {
      if (this.yourId === this.activePlayer.id) {
        await Socket.mySocket.emit('okClicked', Socket.lobby);
      }
    } else {
      this.okClicked();
    }
  }

  private async okClicked() {
    const fieldId = 'fieldId' + this.players[this.activePlayer.id].tile;
    this.ruleMovement = this.ruleset[fieldId].move;

    if (this.ruleMovement !== 0) {
      this.$nextTick(() => {
        (this.$refs['rule'] as any).hide();
      });
      if (this.ruleMovement === -99) {
        this.roll = Math.floor(Math.random() * (this.players[this.activePlayer.id].tile - 0) * -1);
      } else {
        this.roll = this.ruleMovement;
      }
      if (this.gameModeMultiplayer && this.yourId === this.activePlayer.id) {
        await Socket.mySocket.emit('moveInSocket', {
          roll: this.roll,
          playerId: this.yourId,
          lobby: Socket.lobby,
        });
      } else if (!this.gameModeMultiplayer) {
        this.move(this.activePlayer.id);
      }
      return;
    }

    if (this.gameModeMultiplayer && this.yourId === this.activePlayer.id) {
      Socket.mySocket.emit('newActivePlayer', Socket.lobby);
    } else if (!this.gameModeMultiplayer) {
      this.players[this.activePlayer.id].activeTurn = false;

      if (this.activePlayer.id < this.players.length - 1) {
        this.players[this.activePlayer.id + 1].activeTurn = true;
        this.activePlayer = this.players[this.activePlayer.id + 1];
      } else {
        this.players[0].activeTurn = true;
        this.activePlayer = this.players[0];
      }
    }

    document.getElementById('fieldId' + this.activePlayer.tile)!.getBoundingClientRect().left >
    document.getElementById('fieldId4')!.getBoundingClientRect().left
      ? (this.right = true)
      : (this.right = false);
    this.$nextTick(() => {
      (this.$refs['rule'] as any).hide();
    });
  }

  private overlayRight(right: boolean) {
    this.right = right;
  }

  private async move(id: number) {
    this.diceable = false;

    await this.$store.dispatch('move', { id, roll: this.roll });

    if (Math.abs(this.roll) >= 10) {
      await new Promise((resolve) => {
        setTimeout(() => {
          resolve(this.showRule(id));
        }, 500);
      });
    } else {
      await new Promise((resolve) => {
        setTimeout(() => {
          resolve(this.showRule(id));
        }, 500 * (Math.abs(this.roll) + 2));
      });
    }

    this.diceable = true;
  }

  private getPosition(string, index) {
    return string.split('{switch}', index).join('{switch}').length;
  }

  private randomPlayer(id: number) {
    let onePlayer: any = JSON.parse(JSON.stringify(this.players));
    onePlayer.splice(id, 1);
    onePlayer = onePlayer[Math.floor(Math.random() * onePlayer.length)];
    return onePlayer.name;
  }

  private showRule(id: number) {
    const fieldId = 'fieldId' + this.players[id].tile;

    this.rulename = this.ruleset[fieldId].name;

    const countDescription = (this.ruleset[fieldId].description.match(/{switch}/g) || []).length;
    if (countDescription > 0) {
      const descriptions = Math.round(6 / countDescription);
      const firstSwitch = this.getPosition(this.ruleset[fieldId].description, 1);
      const secondSwitch = this.getPosition(this.ruleset[fieldId].description, 2);
      const thirdSwitch = this.getPosition(this.ruleset[fieldId].description, 3);
      const forthSwitch = this.getPosition(this.ruleset[fieldId].description, 4);
      const fifthSwitch = this.getPosition(this.ruleset[fieldId].description, 5);
      let rule = '';
      switch (descriptions) {
        case 6:
          if (this.roll <= 3) {
            rule = this.ruleset[fieldId].description.substring(0, firstSwitch);
            this.ruledescription = rule;
          } else {
            rule = this.ruleset[fieldId].description.substring(firstSwitch + 9);
            this.ruledescription = rule;
          }
          break;
        case 3:
          if (this.roll < 3) {
            rule = this.ruleset[fieldId].description.substring(0, firstSwitch);
          } else if (this.roll < 5) {
            rule = this.ruleset[fieldId].description.substring(firstSwitch + 9, secondSwitch);
          } else {
            rule = this.ruleset[fieldId].description.substring(secondSwitch + 9, thirdSwitch);
          }
          break;
        case 2:
          if (this.roll < 2) {
            rule = this.ruleset[fieldId].description.substring(0, firstSwitch);
          } else if (this.roll < 4) {
            rule = this.ruleset[fieldId].description.substring(firstSwitch + 9, secondSwitch);
          } else if (this.roll < 6) {
            rule = this.ruleset[fieldId].description.substring(secondSwitch + 9, thirdSwitch);
          } else {
            rule = this.ruleset[fieldId].description.substring(thirdSwitch + 9);
          }
          break;
        case 1:
          if (this.roll < 2) {
            rule = this.ruleset[fieldId].description.substring(0, firstSwitch);
          } else if (this.roll < 3) {
            rule = this.ruleset[fieldId].description.substring(firstSwitch + 9, secondSwitch);
          } else if (this.roll < 4) {
            rule = this.ruleset[fieldId].description.substring(secondSwitch + 9, thirdSwitch);
          } else if (this.roll < 5) {
            rule = this.ruleset[fieldId].description.substring(thirdSwitch + 9, forthSwitch);
          } else if (this.roll < 6) {
            rule = this.ruleset[fieldId].description.substring(forthSwitch + 9, fifthSwitch);
          } else {
            rule = this.ruleset[fieldId].description.substring(fifthSwitch + 9);
          }
          break;
      }
      this.ruledescription = rule;
    } else {
      this.ruledescription = this.ruleset[fieldId].description;
    }

    this.ruledescription = this.ruledescription.replace(/{playerName}/g, this.activePlayer.name);

    const randomPlayer = this.randomPlayer(this.activePlayer.id);

    this.ruledescription = this.ruledescription.replace(/{randomPlayer}/g, randomPlayer);

    if (this.ruleset[fieldId].rulerule !== '') {
      if (this.ruleset[fieldId].rulerule === 'Random') {
        let random = 0;
        if (this.gameModeMultiplayer) {
          random = this.random;
        } else {
          random = Math.random();
        }
        random = Math.floor(random * constants.RULERULES[this.$root.$i18n.locale].length);
        this.rulerule = { en: constants.RULERULES.en[random], de: constants.RULERULES.de[random] };
        this.ruledescription = constants.RULERULES[this.$root.$i18n.locale][random];
        this.ruledescription = this.ruledescription.replace(/{playerName}/g, this.activePlayer.name);
      } else if (this.ruleset[fieldId].rulerule === '-') {
        this.rulerule = { en: '', de: '' };
      } else {
        this.rulerule = { en: this.bothRulesets.en[fieldId].rulerule, de: this.bothRulesets.de[fieldId].rulerule };
      }
      this.rulerule.en = this.rulerule.en.replace(/{playerName}/g, this.activePlayer.name);
      this.rulerule.de = this.rulerule.de.replace(/{playerName}/g, this.activePlayer.name);
    }
    (this.$refs['rule'] as any).show();
  }

  private newGame() {
    this.$store.dispatch('newGame');
    this.$router.push({ name: 'newGame' });
  }
}
