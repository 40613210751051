const COLORS = [
  { value: 'orange', de: 'Orange', en: 'Orange' },
  { value: 'green', de: 'Grün', en: 'Green' },
  { value: 'yellow', de: 'Gelb', en: 'Yellow' },
  { value: 'black', de: 'Schwarz', en: 'Black' },
  { value: 'dafuq', de: 'Dafuq', en: 'Dafuq' },
  { value: 'purple', de: 'Lila', en: 'Purple' },
  { value: 'blue', de: 'Blau', en: 'Blue' },
  { value: 'aqua', de: 'Aqua', en: 'Aqua' },
  { value: 'pink', de: 'Pink', en: 'Pink' },
  { value: 'brown', de: 'Braun', en: 'Brown' },
  { value: 'red', de: 'Rot', en: 'Red' },
  { value: 'white', de: 'Weiß', en: 'White' },
];
const RULERULES: any = {
  en: [
    'The words yes and no are honored with a sip',
    'The left neighbor drinks with you (not cumulative)',
    "The word 'drink' rewards you with a drink",
    'You are no longer allowed to accept items from other people',
    'Before you roll the dice, you must praise the game',
    'You are nobles. You are only allowed to address each other elegantly',
    'It is only allowed to drink with the weak hand',
    'Behave! No more swearwords',
    '{playerName} is Medusa. If you look him/her in the eyes, you drink',
    "{playerName} is now the Questionmaster! Anyone who answers him / her questions must end their answer with the words 'Yes, Mr. Questionmaster'",
    'ENGLISCH',
  ],
  de: [
    'Die Wörter Ja und Nein werden mit einem Schluck honoriert',
    'Der Linke Nachbar trinkt mit (nicht kumulativ)',
    "Das Wort 'Trink' belohnt dich mit einem Schluck",
    'Du darfst keine Gegenstände mehr von anderen Personen annehmen',
    'Bevor du würfelst, musst du das Spiel preisen',
    'Sie sind Adlige. Sie dürfen sich nurnoch vornehm ansprechen',
    'Es darf nur mit der schwachen Hand getrunken werden',
    'Benehmt euch! Keine Kraftausdrücke mehr',
    '{playerName} ist Medusa. Wer ihm/ihr in die Augen sieht, der trinkt',
    "{playerName} ist nun der Questionmaster! Jeder ihm/ihr Fragen beantwortet, muss seine Antwort mit den Worten 'Ja Mr. Questionmaster' beenden",
    "You can't point to anyone",
  ],
};

export default {
  COLORS: COLORS,
  RULERULES: RULERULES,
};
