























































































import { Component, Vue } from 'vue-property-decorator';
import MusicService from '@/services/musicService';

@Component({
  components: {},
})
export default class Sidebar extends Vue {
  private languages = [
    { value: 'en', text: 'en', flag: require('@/assets/uk-flag.png') },
    { value: 'de', text: 'de', flag: require('@/assets/germany-flag.png') },
  ];

  private enableSecretModeCounter = 0;

  private enableSecretModeFunction() {
    this.enableSecretModeCounter++;
    if (this.enableSecretModeCounter > 9) {
      (this.$refs['enableSecretModeModal'] as any).show();
    }
  }

  private get enableSecretModeCode() {
    return this.$store.state.enableSecretModeCode;
  }
  private set enableSecretModeCode(enableSecretModeCode: boolean) {
    this.$store.commit('setEnableSecretModeCode', enableSecretModeCode);
  }

  private get flagImg() {
    const flagImg = this.languages.find((e) => e.value === this.$root.$i18n.locale);
    return flagImg.flag;
  }
  private get vibration() {
    return this.$store.state.settings.vibration;
  }
  private set vibration(vibration: boolean) {
    if (vibration) {
      window.navigator.vibrate(1000);
    }
    this.commitToStore({ vibration });
  }
  private get music() {
    return this.$store.state.settings.music;
  }
  private set music(music: boolean) {
    const musicService = new MusicService();
    if (music) {
      musicService.playMusic();
    } else {
      musicService.stopMusic();
    }
    this.commitToStore({ music });
  }
  private get sound() {
    return this.$store.state.settings.sound;
  }
  private set sound(sound: boolean) {
    this.commitToStore({ sound });
  }

  // wanted any
  private commitToStore(settings: any) {
    this.$store.commit('setSettings', settings);
  }
}
