var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"overflow"},[_vm._l((_vm.players),function(player){return _c('b-row',{key:player.id,staticClass:"player"},[_c('b-col',{attrs:{"cols":"5"}},[_vm._v(_vm._s(player.name))]),_c('b-col',{attrs:{"cols":"4"}},[_vm._v(_vm._s(_vm.mappedPlayers(player.color)))]),_c('b-col',{attrs:{"cols":"2"}},[(!_vm.gameModeMultiplayer)?_c('b-button',{staticClass:"deleteButton",attrs:{"size":_vm.deleteSize,"type":"button"},on:{"click":function($event){return _vm.deletePlayer(player.id)}}},[_vm._v(_vm._s(_vm.$t('delete')))]):_vm._e()],1)],1)}),(!_vm.gameModeMultiplayer)?_c('ValidationObserver',{ref:"valid"},[(_vm.addPlayerPossible)?_c('b-row',[_c('b-col',{attrs:{"cols":"5"}},[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input',{model:{value:(_vm.playerName),callback:function ($$v) {_vm.playerName=$$v},expression:"playerName"}}),_c('span',{staticClass:"playerValidation"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,686606292)})],1),_c('b-col',{attrs:{"cols":"4"}},[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-select',{attrs:{"options":_vm.playerColors,"text-field":_vm.$root.$i18n.locale},model:{value:(_vm.playerColor),callback:function ($$v) {_vm.playerColor=$$v},expression:"playerColor"}}),_c('span',{staticClass:"playerValidation"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,3277790888)})],1),_c('b-col',{attrs:{"cols":"2"}})],1):_vm._e()],1):_vm._e()],2),(_vm.gameModeMultiplayer)?_c('b-button',{staticClass:"bierdeckel mt-2 float-left",style:({
      'background-image': 'url(' + require('@/assets/bierdeckel.jpg') + ')',
    }),attrs:{"type":"button"},on:{"click":_vm.copyInvitationLink}},[_vm._v(_vm._s(_vm.$t('copyLink')))]):_vm._e(),(_vm.isMobile && _vm.gameModeMultiplayer)?_c('WhatsAppButton',{staticClass:"share-button--circle mt-3 float-right",attrs:{"btnText":"","shareUrl":_vm.invitationLink}}):_vm._e(),(!_vm.gameModeMultiplayer)?_c('b-button',{staticClass:"bierdeckel mt-2 float-left",style:({
      'background-image': 'url(' + require('@/assets/bierdeckel.jpg') + ')',
    }),attrs:{"type":"button"},on:{"click":_vm.addPlayer}},[_vm._v(_vm._s(_vm.$t('addPlayer')))]):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }