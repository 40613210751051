
























// @ is an alias to /src
import { Component, Prop, Vue } from 'vue-property-decorator';
import Player from '../models/player';
import Ruleset from '../models/ruleset';
@Component({
  components: {},
})
export default class Tile extends Vue {
  @Prop() private fieldNumber!: number;
  @Prop() private ruleset!: Ruleset;
  @Prop() private players!: Player[];
  @Prop() private roll!: number;

  private fieldId: string = 'fieldId' + this.fieldNumber;
  private get rule() {
    return this.ruleset[this.fieldId].name;
  }

  private get highlightStyle() {
    let rolled = '';
    if (Math.abs(this.roll) >= 10) {
      rolled = 1 + 's';
    } else {
      rolled = Math.abs(this.roll / 2) + 1 + 's';
    }
    return { '--transition-delay': rolled };
  }

  private get borderBottom() {
    return this.borderBottomList.indexOf(this.fieldNumber) > -1 ? true : false;
  }
  private get borderLeft() {
    return this.borderLeftList.indexOf(this.fieldNumber) > -1 ? true : false;
  }
  private get borderRight() {
    return this.borderRightList.indexOf(this.fieldNumber) > -1 ? true : false;
  }

  private get tileMoveForward() {
    let filterOn = false;
    this.players.forEach((player) => {
      if (this.fieldNumber === player.tile) {
        filterOn = this.ruleset[this.fieldId].move > 0;
      }
    });
    return filterOn;
  }
  private get tileMoveBackward() {
    let filterOn = false;
    this.players.forEach((player) => {
      if (this.fieldNumber === player.tile) {
        filterOn = this.ruleset[this.fieldId].move < 0;
      }
    });
    return filterOn;
  }
  private get tileRule() {
    let filterOn = false;
    this.players.forEach((player) => {
      if (this.fieldNumber === player.tile) {
        filterOn = this.ruleset[this.fieldId].rulerule !== '' ? true : false;
      }
    });
    return filterOn;
  }
  private get tileHighlighted() {
    let filterOn = false;
    this.players.forEach((player) => {
      if (this.fieldNumber === player.tile) {
        filterOn = true;
      }
    });
    return filterOn;
  }

  private borderBottomList: number[] = [
    0,
    1,
    2,
    3,
    4,
    5,
    6,
    7,
    41,
    42,
    43,
    44,
    45,
    46,
    47,
    30,
    31,
    32,
    33,
    34,
    35,
    59,
    60,
    61,
    62,
    63,
    52,
    53,
    54,
    55,
    69,
    70,
    71,
    66,
    67,
  ];
  private borderLeftList: number[] = [47, 48, 49, 50, 51, 63, 64, 65, 71];
  private borderRightList: number[] = [36, 37, 38, 39, 40, 41, 56, 57, 58, 59, 68, 69];
}
