import Setting from './setting';
import Player from './player';
import Ruleset from './ruleset';
import importedRules from '@/rules';

export default class State {
  settings: Setting = new Setting();
  players: Player[] = [];
  ruleset: Ruleset = importedRules.SpiralingDown;
  gameModeMultiplayer: boolean;
  socket: any = {};
  yourId = 0;
  enableSecretModeCode = '';
}
