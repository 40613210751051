var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"background",style:({
    'background-image': 'url(' + require('@/assets/marmor.jpg') + ')',
  })},[_c('div',{staticClass:"fullscreen text-center"},[_vm._m(0),_c('b-row',{staticClass:"mt-5"},[_c('b-col',[_c('b-button',{staticClass:"gameModeButton",style:({
            'background-image': 'url(' + require('@/assets/tilebackground.jpg') + ')',
          }),on:{"click":_vm.singleplayer}},[_vm._v(_vm._s(_vm.$t('oneDevice')))]),_c('b-button',{staticClass:"gameModeButton ml-3",style:({
            'background-image': 'url(' + require('@/assets/tilebackground.jpg') + ')',
          }),on:{"click":_vm.multiplayer}},[_vm._v(_vm._s(_vm.$t('onlineLobby')))])],1)],1)],1),_c('b-modal',{ref:"disclaimer",attrs:{"centered":"","ok-only":"","no-close-on-esc":"","no-close-on-backdrop":"","title":_vm.title}},[_vm._v(" "+_vm._s(_vm.$t('disclaimer'))+" ")]),_c('b-modal',{ref:"newContent",attrs:{"centered":"","no-close-on-esc":"","no-close-on-backdrop":"","title":_vm.title,"ok-only":""}},[_vm._v(" "+_vm._s(_vm.$t('newContent'))+" ")]),_c('b-modal',{ref:"lobby",attrs:{"hide-backdrop":"","centered":"","no-close-on-esc":"","no-close-on-backdrop":"","ok-only":""},on:{"ok":_vm.handleOk}},[_c('b-row',[_c('b-col',[_vm._v(_vm._s(_vm.$t('name')))]),_c('b-col',[_vm._v(_vm._s(_vm.$t('color')))])],1),_c('ValidationObserver',{ref:"valid"},[_c('b-row',[_c('b-col',[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var errors = ref.errors;
return [_c('b-input',{model:{value:(_vm.playerName),callback:function ($$v) {_vm.playerName=$$v},expression:"playerName"}}),_c('span',[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-col',[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var errors = ref.errors;
return [_c('b-form-select',{attrs:{"options":_vm.playerColors,"text-field":_vm.$root.$i18n.locale},model:{value:(_vm.playerColor),callback:function ($$v) {_vm.playerColor=$$v},expression:"playerColor"}}),_c('span',[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1)],1)],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('h1',{staticClass:"gameName m-auto"},[_c('strong',[_vm._v("Spiraling Down")])])}]

export { render, staticRenderFns }