export default class Setting {
  vibration: boolean;
  music: boolean;
  sound: boolean;

  constructor() {
    this.vibration = true;
    this.music = false;
    this.sound = true;
  }
}
