var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"background",style:({
    'background-image': 'url(' + require('@/assets/marmor.jpg') + ')',
  })},[_c('div',{staticClass:"fullscreen text-center"},[_vm._m(0),_c('b-row',[_c('b-col',{staticClass:"newGameFrame",style:({
          'background-image': 'url(' + require('@/assets/tilebackground.jpg') + ')',
        }),attrs:{"cols":"8"}},[_c('playerList',{attrs:{"gameModeMultiplayer":_vm.gameModeMultiplayer,"players":_vm.players},on:{"addPlayer":_vm.addPlayer,"deletePlayer":_vm.deletePlayer}})],1),_c('b-col',{staticClass:"newGameFrame",style:({
          'background-image': 'url(' + require('@/assets/tilebackground.jpg') + ')',
        })},[_c('b-form-group',{staticClass:"mt-3",attrs:{"label":"Rule Set","disabled":_vm.gameModeMultiplayer && _vm.yourId != 0}},[_c('b-form-select',{attrs:{"options":_vm.rulesets},model:{value:(_vm.ruleset),callback:function ($$v) {_vm.ruleset=$$v},expression:"ruleset"}})],1),(!_vm.gameModeMultiplayer || _vm.yourId === 0)?_c('b-button',{staticClass:"bierdeckel mt-2 float-right footerButoon",style:({
            'background-image': 'url(' + require('@/assets/bierdeckel.jpg') + ')',
          }),attrs:{"type":"button"},on:{"click":_vm.startNewGame}},[_vm._v(_vm._s(_vm.$t('start'))+"!")]):_vm._e()],1)],1)],1)])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('h1',{staticClass:"gameName m-auto"},[_c('strong',[_vm._v("Spiraling Down")])])}]

export { render, staticRenderFns }